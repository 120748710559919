import '../sass/project.scss';

/* Project specific Javascript goes here. */
$(document).ready( function () {
  // Timezone settings
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // e.g. "America/New_York"
  document.cookie = "django_timezone=" + timezone;
  // Alerts
  function closeAlerts(event) {
    if(document.getElementById('alert')) {
      let alert = new Alert(document.getElementById('alert'));
      alert.close()
    }
    if ($("#challenge-expanded").hasClass('show') && !$(event.target).is(".challenge-expanded")) {
      $("#challenge-expanded").collapse('hide');
    }
  }
  $(document).on('click', closeAlerts);

  // Payouts
  function estimatePayout() {
    const basePot = 1000;
    var choice = $("input[type=radio][name=choice]:checked");
    var boost = $(".matchup-header-boost-warning").first();
    var amount = Number($("input[type=number][name=amount]").val());

    try {
      var bet = parseFloat($(".user-bet").first().attr("title").replace(/[^0-9.]/g, ''));
    } catch (e) {
      var bet = null;
    }
    if (boost.length > 0) {
      amount *= 2
    }
    if (choice.length > 0) {
      var choice_val = choice.val();
    } else {
      choice = $(".logo-choice");
    }
    // If there is a bet and a choice selected
    if(amount > 0 && choice_val) {
      var pot = parseFloat($("#pot").attr("title").replace(/[^0-9.]/g, ''));
      var choicePercent = parseFloat(choice.parent().siblings(".percent").text()) / 100;
      var loserPercent = 1 - choicePercent;
      // 1000 is default pot amount
      var loserPot = (pot - basePot ) * loserPercent;
      var winnerPot = (pot - basePot) * choicePercent;
      var betRatio = amount / (winnerPot + amount);
      var payout = Number(parseFloat((betRatio * loserPot) + (betRatio * basePot) + amount).toFixed(0));
      $(".estimated-payout").text("Estimated payout: " + payout.toLocaleString("en-US"));
    // If there is no bet placed and we have an amount to bet
    } else if (bet == null && amount > 0) {
      $(".estimated-payout").text("Please select a team.");
    // If a bet already exists and we're adding to it
    } else if (bet > 0 && amount > 0) {
      var pot = parseFloat($("#pot").attr("title").replace(/[^0-9.]/g, ''));
      pot -= bet
      amount += bet
      var choicePercent = parseFloat(choice.parent().children(".percent").text()) / 100;
      var loserPercent = 1 - choicePercent;
      // 1000 is default pot amount
      var loserPot = (pot - basePot) * loserPercent;
      var winnerPot = (pot - basePot) * choicePercent;
      var betRatio = amount / (winnerPot + amount);
      var payout = Number(parseFloat((betRatio * loserPot) + (betRatio * basePot) + amount).toFixed(0));
      $(".estimated-payout").text("Estimated payout: " + payout.toLocaleString("en-US"));
      $(".total-bet").text("Total bet: " + amount.toLocaleString("en-US"));
    // Trying to bet a negative amount
    } else if (amount < 0) {
      $(".estimated-payout").text("Bet a positive amount!");
      $(".total-bet").text("");
    // When the bet is empty
    } else if (amount == "") {
      $(".estimated-payout").text("");
      $(".total-bet").text("");
    }
  }

  var amountError = $("#error_1_id_amount");
  if (amountError.length > 0) {
    amountError.parent().parent().addClass("pb-4");
  }

  $("input[type=radio][name=choice]").change(function() {
    estimatePayout()
  });

  $("input[name='amount']").on("input",  function() {
    estimatePayout()
  })

  // Bet input clearing
  var element = $('#div_id_amount > input');
  element.click(() => {
    if (element.val() == 0) {
      element.val('')
    }
  })

  // Matchup Bet Edit
  var circlePlus = $('.fa-circle-plus').first();
  circlePlus.click(() => {
    var addForm = $('.matchup-bet-add').first()
    if (addForm.hasClass("d-flex")) {
      addForm.removeClass("d-flex");
      addForm.addClass("d-none");
    } else {
      addForm.removeClass("d-none");
      addForm.addClass("d-flex");
    }
  })


  // Tables
  $('#comp-leaderboard-table').DataTable({
    order: [[5, 'desc']],
    scrollCollapse: true,
    bScrollAutoCss: true,
    paging: false,
    responsive: true,
    columnDefs: [
      { responsivePriority: 1, targets: 1},
      { "width": "20", targets: 1 },
      { responsivePriority: 1, targets: 5},
      { responsivePriority: 2, targets: 4},
      { responsivePriority: 3, targets: 3},
    ],
    language: { search: "", searchPlaceholder: "Search...", emptyTable: "No complete bets found."},
    dom: "<'row'<'#comp-table-name.col-sm-12 col-md-8 text-secondary'l><'col-sm-12 col-md-4'f>>",
    buttons: ['back']
  });
  $('#leaderboard-table').DataTable({
    order: [[6, 'desc']],
    scrollCollapse: true,
    bScrollAutoCss: true,
    paging: false,
    responsive: true,
    columnDefs: [
      { responsivePriority: 1, targets: 1},
      { "width": "20", targets: 1 },
      { responsivePriority: 1, targets: 5},
      { responsivePriority: 1, targets: 6},
      { responsivePriority: 1, targets: 2},
      { responsivePriority: 3, targets: 4},
      { responsivePriority: 3, targets: 3},
    ],
    language: { search: "", searchPlaceholder: "Search...", emptyTable: "No complete bets found."},
    dom: "<'row'<'#table-name.col-sm-12 col-md-8 text-secondary'l><'col-sm-12 col-md-4'f>>",
    buttons: ['back']
  });

  $('#matchup-table').DataTable({
    scrollCollapse: true,
    bScrollAutoCss: true,
    paging: false,
    responsive: true,
    aaSorting: [],
    language: { search: "", searchPlaceholder: "Search..."},
  });


  // Images
  //
  // Fix fail to load images
  document.addEventListener("DOMContentLoaded", function(event) {
    document.querySelectorAll('img').forEach(function(img){
      img.onerror = function(){this.style.display='none';};
    })
  });
  // Navbar
  if (document.getElementById('challenge-icon')) {
    $('.fa-diamond').text(document.getElementById('challenge-icon').dataset.count);
  }
  // Loot
  var cardList 	= $('.cardList').first(),
  cards 		= $('.cards'),
  width 		= 200,
  distance 	= 20 * width
  ;

  for (var i = 0; i < 50; i++) {
    cards.clone().appendTo(cardList);
  }

  var getNextSibling = function (elem, selector) {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  };

  function spin(randomize=true, newDistance=0, speed=4500) {
    var newMargin = 0;
    if (randomize) {
      var newDistance = distance;
      newDistance = Math.floor(Math.random() * cards.length * 5);
      newDistance += cards.length * 10;
      var rand = Math.floor((Math.random()*100)+1);
      newDistance *= rand;
    }
    if (randomize) {
      newMargin = -newDistance
    } else {
      // If an offset is provided, get the diff with the current margin
      var currentMargin = cards.first()[0].style.marginLeft;
      newMargin = parseInt(currentMargin.substring(0, currentMargin.length - 2)) + parseInt(newDistance)
    }
    cards.first().animate({
      marginLeft: newMargin
    }, speed);
  }


  function disableButton(){
    $('#spin').attr("disabled",true);
    $('#spin > .front').css({"background":"hsl(290deg 0% 40%)", "color": "darkgray"});
    $('#spin > .edge').css("background","linear-gradient(to left, hsl(290, 0%, 16%) 0%, hsl(290, 0%, 32%) 8%, hsl(290, 0%, 32%) 92%, hsl(290, 0%, 16%) 100%)");
    setTimeout(() => {
      $('#spin').attr("disabled",false);
      $('#spin > .front').css({"background": "hsl(345deg 100% 47%)", "color": "white"});
      $('#spin > .edge').css("background","linear-gradient(to left, hsl(340, 100%, 16%) 0%, hsl(340, 100%, 32%) 8%, hsl(340, 100%, 32%) 92%, hsl(340, 100%, 16%) 100%)");
    }, 8000)
  }

  async function getBoost() {
    // API things
    const URL = window.location.host
    const API_ENDPOINT = '/api/boosts/get_boost/'
    fetch(API_ENDPOINT).then(response =>
      response.json().then(data => ({
        data: data,
        status: response.status
      })
      ).catch(error => {
        let div = document.createElement('div');
        div.className = 'alert';
        div.classList.add('alert-dismissable')
        div.classList.add('alert-danger')
        div.id = 'alert';
        div.innerText = 'Failed to pay for loot, you need a ticket.'
        document.body.appendChild(div);
      }).then(res => {
        if (res == undefined) {return false} // Guard to stop if failed
        // Start the spin animation
        $('#ticketCount').text($('#ticketCount').text() - 1);
        disableButton();
        setTimeout(spin,100); // 4600ms total
        setTimeout(() => { // Wait for spin to finish, 4750ms
          // Setup for results check
          // First, find center of wheel
          const list = document.getElementsByClassName('cardList')[0];
          const rect = list.getBoundingClientRect();
          const centerX = rect.x + rect.width / 2;
          const centerY = rect.y + rect.height / 2;

          // Get center element
          var selectEl = document.elementFromPoint(centerX, centerY);
          // If this is the card, continue
          if (selectEl.classList.length === 3) {
            var selectChoice = selectEl.classList[selectEl.classList.length - 1]
          } else { // If this is the cardContent, get parent
            selectEl = selectEl.parentElement;
            var selectChoice = selectEl.classList[selectEl.classList.length - 1]
          }
          // Get API response, check if same as center element
          const respData = Object.values(res.data)
          if (selectChoice != respData[0]) {
            // If not, find the closest sibling that matches response
            const closeWin = getNextSibling(selectEl, `.${respData[0]}`)
            if (closeWin) {
              // Get the offset and spin faster
              const newLeft = selectEl.getBoundingClientRect().x - closeWin.getBoundingClientRect().x
              spin(false, newLeft, 1000) // total possible 5750ms
            }
          }
          var boostElement = `#${respData}`
          setTimeout(() => {
            $(boostElement)[0].parentElement.classList.add('animate__fadeOutDown')
            setTimeout(() => {
              var newValue = Number($(boostElement).first().text()) + 1
              $(`.${respData}`).first().text(newValue); // in-nav update
              $(boostElement).first().text(newValue); // loot page update
              setTimeout(() => {
                $(boostElement)[0].parentElement.classList.remove('animate__fadeOutDown')
                $(boostElement)[0].parentElement.classList.add('animate__fadeInUp', 'animate__pulse')
                $(boostElement)[0].parentElement.addEventListener('animationend', () => {
                  $(boostElement)[0].parentElement.classList.remove('animate__fadeInUp', 'animate__pulse')
                });
              }, 750) // Fade In
            }, 750) // Update text
          }, 750) // Fade out animation
        }, 4750) // Wait for spin
      }) // Response json
    ); // Fetch
  }

  $('#spin').click(function() {
    getBoost();
    return false;
  });

  $("#learn-more-btn").click(function() {
    $('html, body').animate({
      scrollTop: $("#info-scroll").offset().top
    }, 100);
  });
  $(".bankruptcy-faq").click(function() {
    $('html, body').animate({
      scrollTop: $("#faq-4").offset().top
    }, 100);
  });

  // Matchup List Redux
  // Horizontal scrolling
  let mouseDown = false;
  let startX, scrollLeft;
  var sliders = null;
  try {
    sliders = document.querySelectorAll('.matchup-list');
  } catch (e) {
    return;
  }

  const startDragging = (e) => {
    var slider = e.target.closest('.matchup-list');
    var link = e.target.closest('.link-unstyled');
    mouseDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
    // Wait 200ms into the mouse down to check for dragging
    // If we see dragging, prevent the click/redirect
    setTimeout(() => {
      if (link != null && slider.classList.contains('dragging')) {
        link.addEventListener('click', (e) => {
          e.preventDefault();
        });
      }
    }, 200);
  }

  const stopDragging = (e) => {
    var slider = e.target.closest('.matchup-list');
    var link = e.target.closest('.link-unstyled');
    mouseDown = false;
    if (link != null && slider.classList.contains('dragging')) {
      setTimeout(() => {
        link.replaceWith(link.cloneNode(true))
      }, 100)
    }
  }

  const move = (e) => {
    var slider = e.target.closest('.matchup-list');
    slider.classList.add('dragging');
    e.preventDefault();
    if(!mouseDown) {
      slider.classList.remove('dragging');
      return;
    }
    const x = e.pageX - slider.offsetLeft;
    const scroll = x - startX;
    slider.scrollLeft = scrollLeft - scroll;
  }

  // Add the event listeners
  if (sliders) {
    sliders.forEach((slider) => {
      slider.addEventListener('mousemove', move, false);
      slider.addEventListener('mousedown', startDragging, false);
      slider.addEventListener('mouseup', stopDragging, false);
      slider.addEventListener('mouseleave', stopDragging, false);
    });
  }
});
